<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <div>智莺支付联盟</div>
      <ChevronRightIcon class="breadcrumb__icon" />
      <div class="breadcrumb--active">{{ $route.meta.title }}</div>
    </div>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <div class="intro-x relative mr-3 sm:mr-6">
<!--      <div class="search hidden sm:block">-->
<!--        <input-->
<!--          type="text"-->
<!--          class="search__input input placeholder-theme-13"-->
<!--          placeholder="Search..."-->
<!--          @focus="showSearchDropdown"-->
<!--          @blur="hideSearchDropdown"-->
<!--        />-->
<!--        <SearchIcon class="search__icon" />-->
<!--      </div>-->
      <a class="notification sm:hidden" href>
        <SearchIcon class="notification__icon" />
      </a>
      <div class="search-result" :class="{ show: searchDropdown }">
        <div class="search-result__content">
          <div class="search-result__content__title">页面</div>
          <div class="mb-5">
            <a href class="flex items-center">
              <div
                class="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full"
              >
                <InboxIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Mail Settings</div>
            </a>
            <a href class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-17 text-theme-11 flex items-center justify-center rounded-full"
              >
                <UsersIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Users & Permissions</div>
            </a>
            <a href class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full"
              >
                <CreditCardIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Transactions Report</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Search -->
    <!-- BEGIN: Notifications -->
    <div v-if="false" class="intro-x dropdown relative mr-auto sm:mr-6">
      <div class="dropdown-toggle notification notification--bullet cursor-pointer">
        <BellIcon class="notification__icon" />
      </div>
      <div class="notification-content pt-2 dropdown-box">
        <div class="notification-content__box dropdown-box__content box">
          <div class="notification-content__title">Notifications</div>
          <div
            v-for="(faker, fakerKey) in $_.take($f(), 5)"
            :key="fakerKey"
            class="cursor-pointer relative flex items-center"
            :class="{ 'mt-5': fakerKey }"
          >
            <div class="w-12 h-12 flex-none image-fit mr-1">
              <img
                alt=""
                class="rounded-full"
                :src="require(`@/assets/images/${faker.photos[0]}`)"
              />
              <div
                class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"
              ></div>
            </div>
            <div class="ml-2 overflow-hidden">
              <div class="flex items-center">
                <a href="javascript:;" class="font-medium truncate mr-5">
                  {{ faker.users[0].name }}
                </a>
                <div class="text-xs text-gray-500 ml-auto whitespace-no-wrap">
                  {{ faker.times[0] }}
                </div>
              </div>
              <div class="w-full truncate text-gray-600">
                {{ faker.news[0].short_content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Notifications -->
    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown w-8 h-8 relative">
      <div
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
      >
        <img alt="" :src="require(`@/assets/images/${$f()[9].photos[0]}`)" />
      </div>
      <div v-if="isDrodown" class="dropdown-box w-56">
        <div class="dropdown-box__content box bg-theme-38 text-white">
          <div class="p-4 border-b border-theme-40">
            <div class="font-medium">{{ userInfo.nickname || "会员昵称" }}</div>
            <div class="text-xs text-theme-41">ID: {{ userInfo.uid }}</div>
          </div>
          <div class="p-2">
            <a
              href
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1:bg-dark-3 rounded-md"
            >
              <UserIcon class="w-4 h-4 mr-2" /> 个人资料
            </a>

            <a
              href
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1:bg-dark-3 rounded-md"
            >
              <LockIcon class="w-4 h-4 mr-2" /> 修改密码
            </a>
            <a
              href
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1:bg-dark-3 rounded-md"
            >
              <HelpCircleIcon class="w-4 h-4 mr-2" /> 帮助
            </a>
          </div>
          <div class="p-2 border-t border-theme-40">
            <span
              href
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1:bg-dark-3 rounded-md"
              @click="onLogout"
            >
              <ToggleRightIcon class="w-4 h-4 mr-2" />
              退出
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import Cookies from "js-cookie";
import request from "@/utils/request";

export default {
  data() {
    return {
      searchDropdown: false,
      isDrodown: true,
    };
  },
  computed: {
    userInfo() {
      if (Cookies.get("userInfo")) {
        return JSON.parse(Cookies.get("userInfo"));
      } else {
        return {};
      }
    },
  },

  methods: {
    onLogout() {
      request({
        url: "/login/logout",
        method: "POST",
      }).then((res) => {
        if (res.code === 0) {
          Cookies.remove("userInfo")
          Cookies.remove('account')
          Cookies.remove('accessToken')
          this.$router.push("/login");
          this.$Toastify({
            node: cash(`<span>退出成功</span>`)[0],
            duration: 3000,
            newWindow: true,
            close: false,
            stopOnFocus: true,
          }).showToast();
          this.isDrodown = false;
        }
      });
    },

    showSearchDropdown() {
      this.searchDropdown = true;
    },
    hideSearchDropdown() {
      this.searchDropdown = false;
    },
  },
};
</script>

<style lang="scss">
span {
  cursor: pointer;
}
</style>
